import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import Home from "./pages/home";
import Privacy from "./pages/privacy";

class App extends Component {
  render() {
    return (
      <>
        <Switch>
          <Route path="/privacy" component={Privacy} />
          <Route path="/" component={Home} />
        </Switch>
      </>
    );
  }
}

export default App;