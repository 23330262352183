import React, { Component } from "react";
import Select from "react-select";
import Thanks from "./thanks";

class Join extends Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
  }

  state = {
    options: [
      { value: "real estate", label: "Real Estate" },
      { value: "hospitality", label: "Hospitality" },
      { value: "other", label: "Other" }
    ],
    type: "join",
    messageSent: false,
    name: "",
    email: "",
    phone: "",
    industry: ""
  };

  componentDidMount() {
    window.scroll({
      left: 0,
      top: this.myRef.current.offsetTop,
      behavior: "smooth"
    });
  }

  handleInputChange = e => {
    const target = e.target;
    const value = target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  };

  handleSelectChange = selectedOption => {
    this.setState({
      industry: selectedOption
    });
  };

  postData = (url = ``, data = {}) => {
    return fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(data)
    }).then(response => {
      if (response.ok) {
        this.setState({
          messageSent: true
        });
      }
      console.log(response);
    });
  };

  handleSubmit = e => {
    e.preventDefault();

    const { name, email, phone, industry } = this.state;
    const data = {
      requesttype: "join",
      name: name,
      email: email,
      number: phone,
      message: industry.value
    };

    this.postData("https://revlity.com/api/contactdetails/", data);

    console.log(data);
  };

  render() {
    const { name, email, phone, options, industry, messageSent } = this.state;

    return (
      <div ref={this.myRef}>
        {messageSent ? (
          <Thanks />
        ) : (
          <div>
            <p>Submit this form to request early access</p>
            <form onSubmit={this.handleSubmit}>
              <div>
                <label htmlFor="name">Name</label>
                <input
                  onChange={this.handleInputChange}
                  value={name}
                  type="text"
                  name="name"
                  id="name"
                  placeholder="Name"
                />
              </div>
              <div>
                <label htmlFor="email">Email address</label>
                <input
                  onChange={this.handleInputChange}
                  value={email}
                  type="email"
                  name="email"
                  id="email"
                  placeholder="Email address"
                />
              </div>
              <div>
                <label htmlFor="phone">Phone number</label>
                <input
                  onChange={this.handleInputChange}
                  value={phone}
                  type="tel"
                  name="phone"
                  id="phone"
                  placeholder="Phone number"
                />
              </div>
              <div>
                <label htmlFor="industry">Work industry</label>
                <Select
                  className="react-select-container"
                  classNamePrefix="custom-select"
                  options={options}
                  value={industry}
                  onChange={this.handleSelectChange}
                />
              </div>
              <button>Join</button>
            </form>
          </div>
        )}
      </div>
    );
  }
}

export default Join;
