import React, { Component } from "react";

class Thanks extends Component {
  render() {
    return (
      <div className="thanks">
        <h1>Thanks</h1>
        <p>Our team will get in touch with you soon. </p>
      </div>
    );
  }
}

export default Thanks;
