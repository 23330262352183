import React, { Component } from "react";
import { Route, Link, Switch } from "react-router-dom";
import Demo from "./demo";
import Join from "./join";
import Contact from "./contact";
import "../App.css";

import smoothscroll from "smoothscroll-polyfill";

import topJumboImg from "../assets/topJumboImg.svg";
import bottomJumboImg from "../assets/bottomJumboImg.svg";
import revlityLogo from "../assets/revlityLogo.svg";
import dashboard from "../assets/dashboard.png";
import getStartImg from "../assets/getStartImg.svg";
import backBtn from "../assets/backBtn.svg";
import bgVideo from "../assets/bgVideo.mp4";
import bgVideoMob from "../assets/bgVideoMob.mp4";
import bgImg from "../assets/bgImg.jpg";
import bgImgMob from "../assets/bgImgMob.jpg";
import salesImg from "../assets/salesImg.svg";
import joinImg from "../assets/joinImg.svg";
import demoImg from "../assets/demoImg.svg";

export default class Home extends Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    // kick off the polyfill!
    smoothscroll.polyfill();

    // Add listener for complete content load, eg: images, stylesheet etc
    window.addEventListener("load", e => {
      this.setState({
        domFullyLoaded: true
      });
    });
  }

  state = {
    greeting: "",
    domFullyLoaded: false,
    portrait: false
  };

  componentDidMount() {
    // Setting Greeting based on time
    let d = new Date();
    let time = d.getHours();

    if (time < 12) {
      this.setState({
        greeting: "Good Morning"
      });
    }
    if (time > 12) {
      this.setState({
        greeting: "Good After Noon"
      });
    }
    if (time > 16) {
      this.setState({
        greeting: "Good Evening"
      });
    }

    // Check for small screens so that to show corresponding portriat video
    if (window.innerWidth < 601) {
      this.setState({
        portrait: true
      });
    }
  }

  handleHelloClick = () => {
    window.scroll({
      left: 0,
      top: this.myRef.current.offsetTop,
      behavior: "smooth"
    });
  };

  render() {
    const { domFullyLoaded, portrait } = this.state;

    return (
      <>
        <div className="navbar">
          <a
            onClick={this.handleHelloClick}
            onTouchStart={this.handleHelloClick}
          >
            Contact us
          </a>
          <a
            target="_blank"
            href="https://create.revlity.com/dashboard/"
            rel="noopener noreferrer"
          >
            Login
          </a>
        </div>
        <div className="jumbotron">
          <div className="topJumbo">
            <img className="topJumboImg" src={topJumboImg} alt="topJumboImg" />
            <img className="revlityLogo" src={revlityLogo} alt="revlityLogo" />
          </div>
          <div className="bgOverlay" />
          {domFullyLoaded ? (
            <div className="bgVideoContainer">
              <video
                loop={true}
                muted={true}
                autoPlay={true}
                poster={portrait ? bgImgMob : bgImg}
                className={bgVideo}
              >
                <source
                  src={portrait ? bgVideoMob : bgVideo}
                  type="video/mp4"
                />
                This device doesn't support
              </video>
            </div>
          ) : null}

          <div className="jumboTypography">
            <h1>Create 3D Virtual tours with ease</h1>
            <h2>Start creating stunning 3D VR experience from 360 photos.</h2>
            <div className="yellowBar desktop" />
            <Link
              onClick={this.handleHelloClick}
              onTouchStart={this.handleHelloClick}
              className="demoBtn"
              to="/demo"
            >
              Schedule a Demo
            </Link>
          </div>
        </div>
        <div className="playerBg">
          <div class="playerLeftLines">
            <div class="one" />
            <div class="two" />
            <div class="three" />
          </div>
          <div className="rightLines">
            <div className="one" />
            <div className="two" />
          </div>
          <div className="player">
            <h4>Fastest webVR player out there</h4>
            <div className="playerTypo">
              <div>
                <p>
                  Revlity webVR player is the best way to share beautiful
                  virtual tours. Easily embed in websites or share through
                  social media. We made sure our VR player runs smoothly on any
                  device.
                </p>
                <ul className="playerFeatures">
                  <li className="lightIcn">Lightweight</li>
                  <li className="navIcn">3D Navigation</li>
                  <li className="gogglesIcn">VR goggles support</li>
                </ul>
              </div>
              {/* <img src={playerImg} alt="playerImg" /> */}
              <iframe
                id="vrPlayer"
                title="Revlity WebVR"
                width="100%"
                height="600px"
                allowvr="yes"
                src="https://revlity.com/vrplayer/grand-hyatt/"
                allowFullScreen
                allow="gyroscope; accelerometer"
              />
            </div>
          </div>
        </div>
        <div className="bottomJumbo">
          <img className="getStartImg" src={getStartImg} alt="getStartImg" />
          {/* <img className="scrollIcn" src={scrollIcn} alt="scrollIcn" /> */}
          <div className="bottomTypography">
            <p>Reimagine 360 photography with Revlity</p>
            <Link
              onClick={this.handleHelloClick}
              onTouchStart={this.handleHelloClick}
              to="/join"
            >
              Request early access
            </Link>
          </div>
        </div>
        <div className="dashboardBg">
          <div className="dashLeftLines">
            <div className="one" />
            <div className="two" />
          </div>
          <div className="rightLines">
            <div className="one" />
            <div className="two" />
          </div>
          <div className="dashboard">
            <h3>A new way of creating 3D virtual tours</h3>
            <img src={dashboard} alt="dashboard" />
            <p>
              You don’t need expensive 3D scanners to create 3D virtual tours.
              Try our intuitive yet powerful creator studio. Drag and drop
              images and just align them to create the lightweight webVR
              experience
            </p>
          </div>
        </div>
        <div ref={this.myRef} className="hello">
          <div className="helloHeader">
            <h1>
              HELLO
              <span>.</span>
            </h1>
            <h2>{this.state.greeting}</h2>
            {window.location.pathname !== "/" ? (
              <Link className="backBtn" to="/">
                <img src={backBtn} alt="backBtn" />
              </Link>
            ) : null}
          </div>
          {window.location.pathname === "/" ? (
            <div className="helloList">
              <div className="helloListItem">
                <div className="helloIcn">
                  <img src={salesImg} alt="salesImg" />
                </div>
                <Link onClick={this.handleHelloSelection} to="/contact">
                  Sales Inquiry
                </Link>
              </div>
              <div className="helloListItem">
                <div className="helloIcn">
                  <img src={demoImg} alt="demoImg" />
                </div>
                <Link onClick={this.handleHelloSelection} to="/demo">
                  Schedule a Demo
                </Link>
              </div>
              <div className="helloListItem">
                <div className="helloIcn">
                  <img src={joinImg} alt="joinImg" />
                </div>
                <Link onClick={this.handleHelloSelection} to="/early-access">
                  Early Access
                </Link>
              </div>
            </div>
          ) : null}

          <Switch>
            <Route exact path="/demo" component={Demo} />
            <Route exact path="/early-access" component={Join} />
            <Route exact path="/contact" component={Contact} />
          </Switch>
        </div>
        <div className="footer">
          <div className="text">
            <p className="copyRight">Copyright &copy; 2019</p>
            <p className="madeBy">Made with ❤️ by Revlity</p>
            <p className="terms">
              <Link to="/privacy">Terms & conditions</Link>
            </p>
          </div>
          <img src={bottomJumboImg} alt="bottomJumboImg" />
        </div>
      </>
    );
  }
}
